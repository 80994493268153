@import url("fontawesome-all.min.css");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik+Maps&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	}

	body{
		background-color: #3C4540;
    overflow-x: hidden;
	}

.poppins-thin {
  font-family: "Poppins", serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", serif;
  font-weight: 800;
  font-style: normal;
}

@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-ms-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}


@keyframes blink {
  25%{
    opacity: 0.5;
    fill: #fffc;
  }
  50%{
    opacity: 0.2;
  }
  75%{
    opacity: 0.5;
    fill: #fff;
  }
}

/* Floating effect */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-60px);
  }
}

/* Floating effect */
@keyframes float2 {
  0%, 100% {
    transform: translateY(0);
  }
  55% {
    transform: translateY(-20px);
  }
}

/* Glowing effect */
@keyframes glow {
  0%, 100% {
    filter: drop-shadow(0 0 5px #00f);
  }
  50% {
    filter: drop-shadow(0 0 15px #00f);
  }
}

/* Engine pulsing effect */
@keyframes enginePulse {
  0%, 100% {
    r: 5;
    fill: rgba(255, 165, 0, 0.8);
  }
  50% {
    r: 7;
    fill: rgba(255, 200, 0, 1);
  }
}


h1{
  font-family: "Teko", serif;
  font-weight: 400;
  font-size: 10cqmax!important;
  text-decoration: none;
  text-align: center!important;
  text-transform: uppercase;
  line-height: 2;
  color: #fff;
  -webkit-text-stroke: 1.0px #d4dfee ;
  text-shadow: 0 0 0 #fff;
  transition: all 250ms;
  opacity: 0.7;
  margin: 5px auto;
  word-spacing: 1vw!important;
  width: 100%;
}

h1:hover {
  text-shadow: 4px 4px 0 #050404;
  transform: translate(-2px, -2px)
}


 h2, h3, h4, h5, h6{
		color: #e4d372;
		font-family: "Poppins", serif;
  		font-weight: 200;
 		font-style: normal;
	}

	p{
		margin: 10px;
	}

	.container{
		width: 100%;
	}


/*  ---------- Nav Styles ----------  */
.nav {
  position: absolute;
  right:120px;
  display: flex;
  justify-content: center;
  max-width: 400px;
  padding-bottom: 100px;
  border-radius: 5px 5px 25px 25px;
  margin: 10px auto 0;
}

.nav [type="checkbox"] {
  position: absolute; 
  background-color: #fff;
  left: -9999px;
}

.nav [type="checkbox"] + label {
  position: relative;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  z-index: 1;
  background: #fff;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.2s;
}

.nav [type="checkbox"] + label:hover {
  background: #007bff;
  color: #fff;
  border: 2px #fff solid;
}

.menu li {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.4s;
}

.menu li:nth-child(1) {
  transition-delay: 0.2s;
}

.menu li:nth-child(2) {
  transition-delay: 0.15s;
}

.menu li:nth-child(3) {
  transition-delay: 0.1s;
}

.menu li:nth-child(4) {
  transition-delay: 0.05s;
}

.menu li a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.menu li a span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 5px));
  width: 100%;
  font-size: 13px;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  color: #fff;
  font-weight: bold;
}

.nav input:checked + label {
  background-color: #00f;
  transform: translateY(calc(-50% + 4px));
  color: #fff;
}

.nav input:checked ~ .menu li:nth-child(1) {
  top: -210px;
  transition-delay: 0.1s;
}

.nav input:checked ~ .menu li:nth-child(2) {
  top: -160px;
  left: calc(50% - 75px);
  transition-delay: 0.2s;
}

.nav input:checked ~ .menu li:nth-child(3) {
  top: -160px;
  left: calc(50% + 75px);
  transition-delay: 0.3s;
}

.nav input:checked ~ .menu li:nth-child(4) {
  top: -110px;
  transition-delay: 0.4s;
}

.nav input:checked ~ .menu li a span {
  opacity: 1;
  transition-delay: 0.9s;
}
/*  ---------- Nav Styles ----------  */
  


/*  ---------- About Styles ----------  */
.about{
  color: #fff;
  margin: 12vh auto 5vh;
  font-size:1.5em;
  display:flex;
}

.about p{
  width: 98vw;
  margin:20px 0px 20px;
  text-align: center!important;
 }

.about h1{
  text-align: center!important;
  font-size: 6cqmax!important;
}
/*  ---------- About Styles ----------  */



/*  --------------------  Header Styles */
#hero {
  height: 760px;
  overflow: hidden;
  position: relative;
}

#content {
  padding: 40px 0px 20px;
}

.spaceship {
  animation: float 3s ease-in-out infinite;
  width: 28%;
  margin: 10% 0 0 18%;
  opacity: 1;
  overflow: hidden;
  background: none!important;
}

.spaceship2 {
  animation: float2 3s ease-in-out infinite;
  width: 30%;
  top:20%;
  left: 16%;
  opacity: 1;
}

.logo{
  z-index: 1000;
  position:absolute;
  right:0%;
  top:2%;
  width: 14%;
  animation: blink 3s linear infinite;
}

.layer {
  background-position: bottom center;
  background-size: auto;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

#hero-mobile {
  display: none;
  height: 320px;
  background-color: #3C4540;
}

.first-section {
  padding: 50px 0 20px 0;
}

#hero,
.layer {
  max-width: 1900px;
  margin: 0 auto;
  overflow: hidden;
}

.layer-bg {
	background-color: #3C4540;
}

.layer-1 {
  background-image: url("images/Building.svg");
  background-size: cover;
  background-position: center 0%;
  background-color: #3C4540;
}

.layer-2 {
display: block;
}

/* .layer-3 {
	background-image: url("images/Astronaught1.svg");
	background-position: right bottom;
	background-size: 50% 90%;
	animation: float2 3s ease-in-out infinite;
	opacity: 0.9;
	z-index: -1;
  } */

.layer-4 {
background-image: url("images/Landscape.svg");
 background-position:center bottom;
 background-size:cover;
 height: 1400px;
 overflow-y: hidden;
 padding-bottom: 5%;
 z-index: 3000!important;
}

.layer-overlay {
  /* background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/272781/ilu_overlay.png"); */
  margin: 0;
}

.header_style{
	background-color: #3C4540;
	z-index: -3;
	position: relative;
}
  /*  --------------------  Header Styles */




/*  ---------- Main Area ---------------- / */
	#main{
		display: block;
		width: 100%;
		background-color: #3C4540;
	}

  .type-animation {
      font-family: "Teko", serif;
      font-optical-sizing: auto;
      font-style: normal;
      font-weight: 400;
      font-size: 5.5em;
      text-align: center;
      margin: 5vh auto;
  }
/*  ---------- Main Area ---------------- / */



/* // .............. Form Styles ................./ */
.contact-form{
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between rows */
  padding: 16px; /* Optional padding */
  text-align: center;
  margin: 10vh 0px 10vh;
}

.contact-form form{
 width: 100%;
 background: rgb(84, 99, 91, 0.3);
 border: 1px rgb(255, 255, 255, 0.6) solid;
 padding: 5vh 0px;
 border-radius:6px;
}

#contact-form input{
  border:2px rgba(245, 229, 142, 0.5) solid!important;
  background: rgb(186,194,149, 0.6);
  border-radius: 8px;
  padding: 15px;
  color:#f9fdff!important;
  font-weight: bolder!important;
  font-size: 1.4em;
  width: 92%;
  box-shadow: 2px 4px 6px rgba(24, 22, 11, 0.3);
  opacity: 0.8;
}

.contact-form h1{
  text-align: center!important;
  font-size: 6cqmax!important;
}

.formButton{
  cursor: pointer;
  background-color: #014792;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 30vw!important;
  border-radius: 8px!important;
  border: 1px #fff solid;
  margin: 4vh auto;
  display: block;
  position: relative;
  top: 6vh;
}

.sentMsg{
  color: #ffffff!important;
}

.sentMsg h4{
  font-weight: 600;
  font-size: 2cqmax;
}
.sentMsg p{
  padding: 10px 0px!important;
}

.contact-form .fa-envelope{
  margin: 0px 20px;
  --fa-animation-duration: 1.5s;
  font-size: 3.5rem;
  color: #68A9BA;
}
/* // .............. Form Styles ................./ */

  

  /* // Testimonials ..................  */
  .testimonials{
    color:#fff!important;
    border-bottom: 1px #68A9BA solid;
    border-top: 1px #68A9BA solid;
    margin: 4vh 0px 14vh;
  }

  .testimonials h1{
  font-size: 6cqmax!important;
  }

  .testimonials .col-md-4{ 
   border-right: 1px rgba(187, 204, 194, 0.7) dotted; 
   }

   .col-md-4:last-of-type {
    border: none!important;
  }
   
  /* // Testimonials ..................  */



  /* // Robot Animation ..................  */
  #robot-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #robot-container svg {
    width: 100vh;
    animation: float 3s ease-in-out infinite;
    height: 100vh;
    right: 20%;
    top: 10vh;
    position: relative;
  }
   /* // Robot Animation ..................  */




  /* Portfolio Styles  ....................... */
  .port_sect{
    padding: 26vh 0;
  }

.port_sect h1{
    font-size: 6cqmax!important;
  }

  .scroll-section {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .scroll-container {
    display: flex;
    gap: 20px; /* Adjust the gap between items */
    padding: 10px;
  }
  
  .scroll-item {
    min-width: 86vw; /* Adjust the width of each item */
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .scroll-item img {
    width: 50vw;
    height: auto;
    border-radius: 8px;
    display: inline-block;
  }
  
  .scroll-item h3 {
    margin: 34px 0;
    font-size: 1.4rem;
    color:#3C4540;
    display: inline-block;
    width: 30vw;
  }
  
  .scroll-text {
    font-size: 0.9rem;
    color: #555;
    padding: 20px 30px;
    display: inline-block;
    width: 30vw;
  }

  .arrow_2 {
position: absolute;
 right: 0;
 top: 50%;
 transform: translateY(-50%);
z-index: 1;
background: rgba(0, 0, 0, 0.7);
color:white;
border:none;
padding: 20px;
cursor: pointer;
  }


.arrow_1{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 20px;
    cursor: pointer;
  }

/* Responsive adjustments */
@media (max-width: 768px) {
  .scroll-item {
    min-width: 92vw; /* Adjust the width of each item */
  }
  .scroll-item img {
    display: block !important;
    width: 98vw;
  }
  
  .scroll-item h3 {
    display: block !important;
    width: 98vw;
  }
  
  .scroll-text {
    display: block !important;
    width: 98vw;
  }
}
  /* Portfolio Styles  ....................... */


  
  /* Actions Buttons   .............................  */
  .actions {
    display: flex;
  }
  .actions button {
    background-color: #007bff;
    color: #fff;
    padding:12px;
    border-radius: 4px;
    font-size: 2.0cqmax!important;
    text-align: center;
    margin: 10px auto;
    border: 2.5px #fff dotted;
  }
  /* Actions Buttons   .............................  */


  /* Service Section  ....................... */
  .services {
    display: block;
    color: #fff;
    width: 100%;
    clear: both;
   margin: 22vh auto;
   text-align:left!important;
  }

  .services .col-md-4{
    margin-top: 30px;
  }

  .services ul {
    display:grid;
    padding: 10px 28px;
  }

  .services ul li{
list-style:disc;
  }

  .services h2{
    font-size:4cqmin;
    font-weight: 300;
    display: inline;
  }

  .services i{
    font-size: 1.5rem;
    display: inline;
    padding-right: 10px;
    color:#68A9BA!important;
  }

  .services h1{
    text-align: center!important;
    font-size: 6cqmax!important;
  }

.services .fa-beat-fade {
  text-transform: lowercase!important;
  line-height: 6vh;
  --fa-beat-fade-opacity: 0.1;
  --fa-animation-duration: 2.2s;
}
    /* Service Section  ....................... */



      /* ////   FAQ ............................. */
    .faq-container {
      max-width: 80vw;
      width: 100%;
      padding: 20px;
      margin: 10vh auto;
      background-color: rgba(38, 44, 49, 0.4);
      border-radius: 2px;
      border:1px  #b0cae6 double;
  }
  
  .faq-container h1 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 6cqmax!important;
  }
  
  .faq-item {
      margin-bottom: 10px;
      clear: both;
      display:flexbox;
  }
  
  details {
      background: #f1f1f1;
      border-radius: 4px;
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  }
  
  details:hover {
      background-color: #e1e1e1;
  }
  
  summary {
      font-weight: bold;
      color: #333;
      outline: none;
  }
  
  details[open] summary {
      margin-bottom: 10px;
  }
  
  details p {
      margin: 0;
      padding: 10px;
      background: #fff;
      border-radius: 4px;
      animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
      from {
          opacity: 0;
          transform: translateY(-10px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  /* ////   FAQ ............................. */



  /* ////   Blog ............................. */
  .blogPosts {
    margin: 12vh auto 6vh;
    border-bottom: 1px #68A9BA solid;
    border-top: 1px #68A9BA solid;
  }

  .blogPosts div{
    margin: 10vh auto;
  }

  .blogPosts h1{
    font-size: 6cqmax!important;
    text-align: center!important;
  }

  .blogPosts a{
    color:#e4d372; 
    border: 1px #68A9BA solid;
    border-radius: 6px;
    padding: 20px;
    margin: 5vh auto;
    clear: both;
    display: block;
  }

  .blogPosts a:hover{
    background: rgb(104, 169, 186), 0.4!important;
    display: block;
    color:#e1e1e1;
    font-weight: 300;
  }
   /* ////   Blog ............................. */




    /* ////   Footer ............................. */
    #footer{
      height: 60vh;
      text-align: center;
      color: #fff;
      font-weight: 300;
      font-size: 0.8em;
      background-image: url("images/FooterSVG.svg");
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      }
    
      #footer a{
      text-decoration: none;
      }
    
      #footer a:hover{
      color:#e4d372;
      }
    
      #footer .inner{
      text-align:center;
      height: auto;
      display: block;
      position: relative;
      top: 28vh;
      }
    
      #footer .icons{
      font-size: 10vh;
      padding: 20px auto; 
      color:#68A9BA!important;
      opacity:0.7;
      }
    
      #footer ul li{
      display:inline;
      padding: 5px 20px;
      }

    .footerContact{
      width:80vw;
      margin: 10px auto;
      text-align: right;
      height: 2vh;
      font-size: 1.4cqmax;
      position: relative;
      top: 48px;
    }

      .copyright{
        font-size: 2.6vh;
        }
    /* ////   Footer ............................. */


    /*  ........... Back To Top ................ */
    #myBtn {
      display: none; /* Hidden by default */
      position: fixed; /* Fixed/sticky position */
      bottom: 20px; /* Place the button at the bottom of the page */
      right: 30px; /* Place the button 30px from the right */
      z-index: 99; /* Make sure it does not overlap */
      border: 2px #fff solid; /* Remove borders */
      outline: none; /* Remove outline */
      background-color: #007bff; /* Set a background color */
      color: white; /* Text color */
      cursor: pointer; /* Add a mouse pointer on hover */
      padding: 15px; /* Some padding */
      border-radius: 10px; /* Rounded corners */
      font-size: 18px; /* Increase font size */
      opacity: 0.7;
    }
    
    #myBtn:hover {
      background-color: #555; /* Add a dark-grey background on hover */
      opacity: 1;
      border: 2px #d5e25d solid;
    }
/*  ........... Back To Top ................ */


/*  ........... For Phone ................ */
  @media only screen and (max-width: 768px) {
    body{
      overflow-x: hidden!important;
    }
	#form_div small{
		color: #f78e8ee2;
		font-size: 0.6em;
		line-height: 30%;
	  }
	.layer-3 {
	  background-size: 170% 80%;
	  background-position: left bottom;
  }
  
  .logo{
  width:45%;
  
  }
  .tutorial-link {
	color: #fff;
	font-size: 18px;
	text-decoration: underline;
  }
  
  .tutorial-link:hover {
	color: #ede0d5;
  }

  .layer-3 {
	background-size: 70% 90%;
	background-position: center bottom;
  }

  #main p{
	padding: 0px 20px;
  }

  #main{
	margin-top: -17vh;
  }
  .spaceship{display: none}

  .nav {
    right:22vw;
    margin: -16vh 0px;
  }

  h1{
		font-size: 5.2em!important;
    line-height: 12vh;
    margin: 5vh auto;
  }
  .type-animation {
    font-size: 2.4em;
  }
  .intro h1{
    font-size: 1.5rem;
}

.intro a{
    font-size: 4rem;
}

.animated-element  img{
  min-width: 94vw;
}

.services {
 margin: 8vh auto!important;
}

.project-image img {
  width: 100%!important
}

.projects-container h1, .services h1, .contact-form h1, .testimonial-header, .faq-container h1{
  font-size: 3.7rem!important;
}

.main_h1{
  font-size: 3.5rem!important;
  margin: 20px auto;
  padding: 20px auto;
}

.services  .col-lg{
  padding-bottom: 30vh;
  margin: 2vh auto!important;;
  height: 20vh;
  width: 80vw;
  vertical-align: middle;
  font-size: large;
  text-align: left!important;
}

.about p{
  font-size:0.8em!important;
  text-align: left!important;
}

.services  ul{
  margin: 10px auto;
}
.services  li{
  list-style-type: disc;
}

.contact-form{
  display: block;
  flex-direction:initial!important;
}

.contact-form input{
  width: 98%;
  clear: both!important;
  display:block!important;
  margin: 0px auto 20px!important;
  border-radius: 2vw!important;
}

.formButton{
  width: 60vw!important;
  margin: 0px auto!important;
}

.actions button {
  font-size: 2.8cqmax!important;
  padding: 10px;
}

.footerContact{
  font-size: 2.4cqmax;
  top: 60px;
}
.services .fa-beat-fade {
  line-height: 10vh;
 }

 .contact-form .fa-envelope{
  margin: 0px 10px;
  font-size: 1.2em;
}
  }
  
/* .........For Ipad  ................... */
  @media only screen and (min-width: 766px) {
  
  }

  @media only screen and (min-width: 1024px) {
   

  }